import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    dashboardPortfolioInventoryReset,
    dashboardInitPortfolioSecurities
} from '../../../actions/dashboard.actions';
import { isRequesting, isRequestNone } from '../../../utils';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { DashboardPortfolioBwicSecurities } from './DashboardPortfolioBwicSecurities';
import { DashboardPortfolioInventorySecurities } from './DashboardPortfolioInventorySecurities';
import { AppState } from '../../../types/state/AppState';
import { TabItem, TabList } from '../../bidding/common/tab-list';
import { BwicInventoriesTabs } from '../../../types/dashboard/BwicInventoriesTabs';
import { PortfolioAlertsPreferences } from '../../portfolio/portfolio-alert/PortfolioAlertsPreferences';
import { useEmailPreferences } from '../../../effects/data-accessors/useEmailPreferences';
import { PageConfigType } from '../../../types/page-config/PageConfigType';
import { BwicFilterType } from '../../../types/filters/FilterState';
import { usePageConfig } from '../../common/pageConfig/usePageConfig';

interface PortfolioWidgetProps {
    showAlerts?: boolean;
}

export const PortfolioWidget = ({ showAlerts = true }: PortfolioWidgetProps) => {
    const dispatch = useDispatch();

    const hasPortfolio = useSelector((s: AppState) => s.dashboard.portfolioExist);
    const requestStateFetchPortfolioSecurities = useSelector((s: AppState) => s.dashboard.requestStateFetchPortfolioSecurities);
    const requestStateFetchPortfolioExist = useSelector((s: AppState) => s.dashboard.requestStateFetchPortfolioExist);

    useEmailPreferences();
    usePageConfig(PageConfigType.Portfolio, false, BwicFilterType.Portfolio);

    useEffect(() => {
        dispatch(dashboardInitPortfolioSecurities());

        return () => {
            dispatch(dashboardPortfolioInventoryReset());
        };
    }, [dispatch]);

    const [activeTab, setActiveTab] = useState(BwicInventoriesTabs.Bwic);

    return (
        <DashboardWidget className="portfolio-alerts container-flex">
            <DashboardSkeleton
                inProgress={
                    isRequestNone(requestStateFetchPortfolioSecurities) ||
                    isRequesting(requestStateFetchPortfolioSecurities) ||
                    isRequesting(requestStateFetchPortfolioExist)
                }
            >
                <DashboardWidgetHeader className="flex-row">
                    <h3>My Securities on</h3>
                    {showAlerts &&
                        <div className="flex-item-right">
                            <PortfolioAlertsPreferences disabled={!hasPortfolio} />
                        </div>
                    }
                </DashboardWidgetHeader>
                <DashboardWidgetContent requestState={requestStateFetchPortfolioSecurities}>
                    <TabList>
                        <TabItem
                            onClick={() => setActiveTab(BwicInventoriesTabs.Bwic)}
                            active={activeTab === BwicInventoriesTabs.Bwic}
                            title="BWICs"
                        />
                        <TabItem
                            onClick={() => setActiveTab(BwicInventoriesTabs.Inventory)}
                            active={activeTab === BwicInventoriesTabs.Inventory}
                            title="Dealer Inventory"
                        />
                    </TabList>
                    {
                        activeTab === BwicInventoriesTabs.Bwic
                            ? <DashboardPortfolioBwicSecurities />
                            : <DashboardPortfolioInventorySecurities />
                    }
                </DashboardWidgetContent>
            </DashboardSkeleton>
        </DashboardWidget>
    )
}
