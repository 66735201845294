import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AppState } from '../../../types/state/AppState';
import { numericUtils } from '../../../utils/numeric.utils';
import { DtcSellingBuyingChart } from './DtcSellingBuyingChart';
import { isRequesting } from '../../../utils';
import { chartDtcSellingBuyingConstants } from '../../../constants/dashboard/chartDtcSellingBuying';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { WidgetDescription } from '../WidgetDescription';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';

const chartName = "TRACE Broker-Dealer Net Flow";

export function DtcSellingBuyingWidget() {
    const dtcSellingBuying = useSelector((s: AppState) => s.dashboard.dtcSellingBuying);
    const requestStateDtcSellingBuying = useSelector((s: AppState) => s.dashboard.requestStateDtcSellingBuying);

    const data = useMemo(() => dtcSellingBuying.map(d => {
        const amountByMonth = (d.igCustomerSell + d.hyCustomerSell - d.igCustomerBuy - d.hyCustomerBuy) * 1000;
        const y = numericUtils.round(amountByMonth / 1000000, 0);
        const x = moment(d.period).format(chartDtcSellingBuyingConstants.dateFormat);
        return { x, y }
    }), [dtcSellingBuying]);

    const minMaxYear = useMemo(() => {
        const dates = dtcSellingBuying.map(d => new Date(d.period).getTime());
        const maxYear = new Date(Math.max.apply(null, dates)).getFullYear();
        const minYear = new Date(Math.min.apply(null, dates)).getFullYear();
        if (maxYear === minYear) {
            return maxYear;
        }
        return `${minYear || ''} - ${maxYear || ''}`;
    }, [dtcSellingBuying]);

    const chart = useMemo(() => <DtcSellingBuyingChart data={data} />, [data]);

    return (
        <DashboardWidget className="dtc-selling-buying">
            <DashboardSkeleton inProgress={isRequesting(requestStateDtcSellingBuying)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.getDtcSellingBuying}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-trace-bd-net-flow"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}<span className="date">USD, MM</span></h3>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent requestState={requestStateDtcSellingBuying}>
                            {chart}
                            <div className="agenda">
                                <div className="dealer-net-flow display-inline">Dealer Net Flow</div>
                                <div className="cumulative-position display-inline">
                                    {minMaxYear} Cumulative Position
                                </div>
                            </div>
                            <WidgetDescription
                                id="dtc-selling-buying-clamp"
                                lines={2}
                                text="Aggregate CLO/CDO flows to/from customers of broker-dealers are reported publicly by TRACE.
                                The cumulative balance of aggregated dealer positions can be used to see if dealers are gathering or
                                offloading inventory. This figure shows the balance of supply and demand in the market; a positive
                                net flow to dealers indicates more supply than demand, and a negative net flow indicates more demand
                                than supply"
                            />
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    )
}
