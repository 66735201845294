import React, { useMemo } from 'react';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget'
import { WidgetDescription } from '../WidgetDescription';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { AAAPriceLevelChart } from './AAAPriceLevelChart';
import { isRequesting, numericUtils } from '../../../utils';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';

const chartName = "AAA Px Talk and Color Levels";

export function AAAPriceLevelWidget() {
    const aaaPriceLevel = useSelector((s: AppState) => s.dashboard.aaaPriceLevel);
    const requestStateFetchAAAPriceLevel = useSelector((s: AppState) => s.dashboard.requestStateFetchAAAPriceLevel);
    const chart = useMemo(() => {
        const chartData = aaaPriceLevel.map(d => ({
            ...d,
            avgColor: d.avgColor ? numericUtils.round(d.avgColor, 2) : null,
            avgPriceTalk: d.avgPriceTalk ? numericUtils.round(d.avgPriceTalk, 2) : null,
            minAvgPriceTalk: d.minAvgPriceTalk ? numericUtils.round(d.minAvgPriceTalk, 2) : null,
            maxAvgPriceTalk: d.maxAvgPriceTalk ? numericUtils.round(d.maxAvgPriceTalk, 2) : null,
        }));
        return <AAAPriceLevelChart chartData={chartData} />
    }, [aaaPriceLevel]);

    return (
        <DashboardWidget className="aaa-spread">
            <DashboardSkeleton inProgress={isRequesting(requestStateFetchAAAPriceLevel)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.getMedianTripleA}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-aaa-px-talk-color-level"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}</h3>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestStateFetchAAAPriceLevel}
                    >
                            {chart}
                            <div className="agenda">
                                <div className="avg-color display-inline">Avg Color</div>
                                <div className="avg-price display-inline">Avg Px Talk</div>
                                <div className="range display-inline"><span />Px Talk Range</div>
                            </div>
                            <WidgetDescription
                                id="percent-of-trace-clamp"
                                lines={1}
                                text="This graph plots monthly average AAA post-trade color and monthly average Px talk, including the range of Px talk within each month. Wider ranges of Px talk show that broker-dealers had differing predictions of the traded price. Large differences between color levels and Px talk imply broker-dealers were inaccurate in these predictions while small differences imply high forecasting accuracy by broker dealers."
                            />
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    )
}
