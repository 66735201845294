import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { AppState } from '../../../types/state/AppState';
import { constants, SORT, SORTING_TYPE } from '../../../constants';
import { Table } from '../../bidding/common/table';
import { isRequesting, isRequestSuccess } from '../../../utils';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { Pagination } from '../tables/Pagination';
import { useSortedList } from '../../../effects';
import { BiggestMoversDetails } from '../../../types/dashboard/BiggestMovers';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { DashboardNoSelectedFilters } from '../DashboardNoSelectedFilters';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import dateTimeUtils from '../../../utils/dateTime.utils';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { formatUtils } from '../../../utils/format.utils';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';

const chartName = "Biggest Movers";

export function BiggestMoversWidget() {
    const itemsOnPage = 7;
    const biggestMovers= useSelector(biggestMoversSelector);
    const requestStateFetchBiggestMovers = useSelector((s: AppState) => s.dashboard.requestStateFetchBiggestMovers);
    const filterActive = useSelector((s: AppState) => s.dashboard.filterActive);
    const biggestMoversDetails = useSelector((s: AppState) => s.dashboard.biggestMovers.details);

    const [sortBy, setSortBy] = useState('absDifference');
    const [sortDirection, setSortDirection] = useState(SORT.DESC);

    const [sortedDataItems] = useSortedList(biggestMovers.details, sortBy, sortDirection);

    const setSortParams = (field: string, direction: string) => {
        setSortBy(field);
        setSortDirection(direction);
    };

    const renderTable = useMemo(() => {
        if (isRequestSuccess(requestStateFetchBiggestMovers) && !biggestMoversDetails.length) {
            return <DashboardNoSelectedFilters text="No securities have been traded in the past 30 days." />
        }
        if (isRequestSuccess(requestStateFetchBiggestMovers) && !sortedDataItems.length) {
            return <DashboardNoSelectedFilters />
        }
        return (
            <Pagination list={sortedDataItems} itemsOnPage={itemsOnPage}>
                {(items: BiggestMoversDetails[]) => (
                    <Table dataItems={items} columns={biggestMoversColumns} onSort={setSortParams} />
                )}
            </Pagination>
        )
    }, [sortedDataItems, requestStateFetchBiggestMovers, biggestMoversDetails.length]);

    return (
        <DashboardWidget filterActive={filterActive} className="biggest-movers height-size01">
            <DashboardSkeleton inProgress={isRequesting(requestStateFetchBiggestMovers)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.getBiggestMovers}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-biggest-movers"
                >
                    <DashboardWidgetHeader>
                        <h3>
                            {chartName}
                            <span className="date">rolling 30 days</span>
                        </h3>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestStateFetchBiggestMovers}
                        description={
                            isRequestSuccess(requestStateFetchBiggestMovers) && sortedDataItems.length
                                ? 'The Biggest Movers table shows which securities that traded in the past 30 days had the largest price change from traded levels for the same security during the previous 30 days. DNT securities are not considered.'
                                : ''
                        }
                    >
                            {renderTable}
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    )
}

const biggestMoversSelector = createSelector([
    (s: AppState) => s.dashboard.biggestMovers,
    (s: AppState) => s.dashboard.filter.selectedRatings,
    (s: AppState) => s.dashboard.filter.selectedCurrencies,
], (
    biggestMovers,
    selectedRatings,
    selectedCurrencies
) => {
    let details = biggestMovers.details;
    if (selectedRatings.length) {
        details = details.filter(i => selectedRatings.some(r => r === i.rating))
    }
    if (selectedCurrencies.length) {
        details = details.filter(i => selectedCurrencies.some(c => c === i.currency))
    }
    return {
        ...biggestMovers,
        details
    }
});

const biggestMoversColumnsDefinitions: IColumnDefinition<BiggestMoversDetails>[] = [
    {
        columnKey: 'ticker',
        renderColumnHeaderContent: () => 'Ticker',
        renderColumnContent: entity => entity.ticker,
        headerClassName: 'data-list-ticker',
        bodyClassName: 'data-list-ticker',
        sortingField: 'ticker',
        sortingType: SORTING_TYPE.string,
    },{
        columnKey: 'rating',
        renderColumnHeaderContent: () => 'Rtg',
        renderColumnContent: entity => entity.rating,
        headerClassName: 'data-list-rating',
        bodyClassName: 'data-list-rating',
        sortingField: 'rating',
        sortingType: SORTING_TYPE.rating
    },{
        columnKey: 'last-price',
        renderColumnHeaderContent: () => 'Last Price',
        renderColumnContent: entity => formatUtils.formatDecimal(entity.lastPrice, 2),
        headerClassName: 'text-right data-list-price p-r-0',
        bodyClassName: 'text-right data-list-price p-r-0',
        sortingField: 'lastPrice',
        sortingType: SORTING_TYPE.number,
    },{
        columnKey: 'prev-price',
        renderColumnHeaderContent: () => 'Prev Price',
        renderColumnContent: entity => formatUtils.formatDecimal(entity.prevPrice, 2),
        headerClassName: 'text-right data-list-price p-r-0',
        bodyClassName: 'text-right data-list-price p-r-0',
        sortingField: 'prevPrice',
        sortingType: SORTING_TYPE.number
    },{
        columnKey: 'difference',
        renderColumnHeaderContent: () => 'Diff',
        renderColumnContent: entity => formatUtils.formatDecimal(entity.difference, 2),
        headerClassName: 'text-right data-list-diff p-r-0',
        bodyClassName: 'text-right data-list-diff p-r-0',
        sortingField: 'difference',
        sortingType: SORTING_TYPE.number,
    },{
        columnKey: 'abs-difference',
        renderColumnHeaderContent: () => 'Abs Diff',
        renderColumnContent: entity => formatUtils.formatDecimal(entity.absDifference, 2),
        headerClassName: 'text-right data-list-abs-diff hidden',
        bodyClassName: 'text-right data-list-abs-diff hidden',
        sortingField: 'absDifference',
        sortingType: SORTING_TYPE.number,
    },{
        columnKey: 'last-date',
        renderColumnHeaderContent: () => 'Last Date',
        renderColumnContent: entity => entity.lastDate
            ? dateTimeUtils.utcToLocalString(entity.lastDate, constants.dashboardDateFormat) : constants.emptyPlaceholder,
        headerClassName: 'data-list-last-date hidden',
        bodyClassName: 'data-list-last-date hidden',
        sortingField: 'lastDate',
        sortingType: SORTING_TYPE.date,
    },{
        columnKey: 'prev-date',
        renderColumnHeaderContent: () => 'Prev Date',
        renderColumnContent: entity => entity.prevDate
            ? dateTimeUtils.utcToLocalString(entity.prevDate, constants.dashboardDateFormat) : constants.emptyPlaceholder,
        headerClassName: 'data-list-prev-date hidden',
        bodyClassName: 'data-list-prev-date hidden',
        sortingField: 'prevDate',
        sortingType: SORTING_TYPE.date
    },{
        columnKey: 'days-diff',
        renderColumnHeaderContent: () => 'Days Diff',
        renderColumnContent: entity => entity.daysBetweenTrade,
        headerClassName: 'text-right data-list-days-diff hidden',
        bodyClassName: 'text-right data-list-days-diff hidden',
        sortingField: 'daysBetweenTrade',
        sortingType: SORTING_TYPE.number,
    },{
        columnKey: 'price-velocity',
        renderColumnHeaderContent: () => 'Price Velocity',
        renderColumnContent: entity => formatUtils.formatDecimal(entity.priceVelocity, 2),
        headerClassName: 'text-right data-list-price-velocity hidden',
        bodyClassName: 'text-right data-list-price-velocity hidden',
        sortingField: 'priceVelocity',
        sortingType: SORTING_TYPE.number,
    }
];

const biggestMoversColumns = biggestMoversColumnsDefinitions.map(c => {
    return new ColumnBuilder(c);
});
