import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { PxTalkDeviationChart, PxTalkDeviationChartData, PxTalkDeviationData } from './PxTalkDeviationChart';
import { numericUtils } from '../../../utils/numeric.utils';
import { Rating } from '../../../types/enums/Rating';
import { ratingColors } from '../../../constants/dashboard/ratings';
import { AppState } from '../../../types/state/AppState';
import { isRequesting } from '../../../utils';
import { RatingColorLabel } from '../RatingColorLabel';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { chartPxTalkDeviation } from '../../../constants/dashboard/chartPxTalkDeviation';
import { RatingWithGroup } from '../../../types/dashboard/RatingGroup';
import { WidgetDescription } from '../WidgetDescription';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { Currencies } from '../../../types/enums/Currency';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';

const chartName = "Px Talk Deviation from Color: Liquidity Barometer";

export function PxTalkDeviationWidget() {
    const currentFilter = useRef<RatingWithGroup[]>([]);

    const pxTalkDeviation = useSelector((s: AppState) => s.dashboard.pxTalkDeviation);
    const requestStateFetchPxTalkDeviation = useSelector((s: AppState) => s.dashboard.requestStateFetchPxTalkDeviation);
    const filter = useSelector((s: AppState) => s.dashboard.filter);
    const notAppliedFilter = useSelector((s: AppState) => s.dashboard.notAppliedFilter);
    const filterActive = useSelector((s: AppState) => s.dashboard.filterActive);

    const [filterChanged, setFilterChanged] = useState(false);

    const { selectedRatings } = filter;
    const notAppliedSelectedRatings = notAppliedFilter.selectedRatings;

    useEffect(() => {
        const isActive =
            !isEqual(currentFilter.current, notAppliedSelectedRatings) &&
            notAppliedSelectedRatings.some(selectedRating => chartPxTalkDeviation.ratings
                .some(rating => rating === selectedRating)
            ) && filterActive;
        setFilterChanged(isActive);
        currentFilter.current = notAppliedSelectedRatings
    }, [notAppliedSelectedRatings, setFilterChanged, filterActive]);

    const average = (array: number[] = []) => {
        const defaultValue = -10;
        const sum = array.reduce((sum, current) => {
            sum += current;
            return sum;
        }, 0);
        const result = numericUtils.divideSafe(sum, array.length);
        return result ? numericUtils.round(result, 2) : defaultValue
    };

    const data: PxTalkDeviationData = useMemo(() => {
        let maxValue = 0;

        const data = pxTalkDeviation.map(p => {
            const result: PxTalkDeviationChartData = {
                x: [],
                y: [],
                markersColors: [],
                ratings: [],
            };
            const sortedByRatings: { [rating: string]: number[] } = {};
            p.priceTalkDeviation
                .filter(pxTalk => (
                    pxTalk.currency === Currencies[0] &&
                    (
                        (
                            selectedRatings.length &&
                            chartPxTalkDeviation.ratings
                                .some(rating =>
                                    selectedRatings.some(selectedRating => selectedRating === rating)
                                )
                        )
                            ? selectedRatings.some(r => r === pxTalk.rating)
                            : true)
                ))
                .forEach(pxTalk => {
                    const rating = pxTalk.rating;
                    if (!sortedByRatings[rating]) {
                        sortedByRatings[rating] = []
                    }
                    sortedByRatings[rating].push(pxTalk.avgDeviation)
                });
            chartPxTalkDeviation.ratings.forEach(r => {
                const yValue = average(sortedByRatings[r]);
                result.x.push(p.name);
                result.y.push(yValue);
                result.ratings.push(r);
                result.markersColors.push(ratingColors[r]);
                if (maxValue < yValue) {
                    maxValue = yValue
                }
            });
            return result
        });

        return { data, maxValue }
    }, [pxTalkDeviation, selectedRatings]);

    const chart = useMemo(() => <PxTalkDeviationChart chartData={data} />, [data]);

    return (
        <DashboardWidget filterActive={filterChanged} className="px-talk-deviation">
            <DashboardSkeleton inProgress={isRequesting(requestStateFetchPxTalkDeviation)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.getPriceTalkDeviation}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-px-talk-deviation-from-color"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}</h3>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent requestState={requestStateFetchPxTalkDeviation}>
                            {chart}
                            <RatingColorLabel ratings={[Rating.AAA, Rating.AA, Rating.A, Rating.BBB, Rating.BB]} />
                            <WidgetDescription
                                id="px-talk-deviation-clamp"
                                lines={2}
                                text="Px Talk Deviation from Color, or PTDC, shows the aggregate accuracy of broker-dealer Px Talk.
                             This metric can be used to track price dislocation in the CLO market. As stress rises and liquidity falls,
                             broker-dealers become less accurate on aggregate. Higher PTDC values indicate lower accuracy.
                             For each trade where color is provided, we take the absolute difference between the Color price and
                             the average Px Talk submitted for the listed security. Because Px Talk is sometimes given by a range,
                             we convert any ranges to median points within the implied bounds."
                            />
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    )
}
